import React, { useRef, memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import 'swiper/css/pagination';

import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import { getFromLocalStorage } from "../../helpers/helpers";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './style.css';

const ExclusiveCollectionsMemorize = (props) => {
  const [imgPath, setImgPath] = useState('');
  const [productList, setProductList] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("category_data987456321", props.category);
    const storedData = getFromLocalStorage("allProductData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setImgPath(parsedData?.img_thumb_path);
      setProductList(parsedData?.message);
      console.log("Parsed data:", parsedData);
    }
  }, []);

  const exclusiveCollectionData = productList
    ?.filter(item => item.is_exclusive_collections === 1)
    .slice(start, end);

  const nextBtn = () => {
    setStart(prev => prev + 1);
    setEnd(prev => prev + 1);
  };

  const prevBtn = () => {
    setStart(prev => prev - 1);
    setEnd(prev => prev - 1);
  };

  const getCategorySlug = (catId) => {
    var name = props.category?.find(cat=>cat.id===catId)?.category_name.toLowerCase().replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
      return name;
  }


  const setProductdataInLocalstorage = (id,product_name,color_name, cat_id) => {
    const prodTitle = product_name
    const prodColor = color_name
    const category_slug= "/collections/"+getCategorySlug(cat_id)+"/"
    const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+id
    const titleToLink = category_slug+"saree/"+slug?.toLowerCase();
    localStorage.setItem("ProductId", id)
    navigate(titleToLink);
  }

  const exCollection = useRef();
  // const swiperSlide = useSwiperSlide();

  const handlenext = () => {
      exCollection.current.swiper.slideNext();
  };
  const handleprev = () => {
      exCollection.current.swiper.slidePrev();
  };

  return (
    <div className='w-full px-4 md:px-14 my-5 relative'>
      <h1 className='font-Lucida text-[2em] md:text-[3em] font-medium text-red-600 red-title-shadow text-center'>Exclusive Collections</h1>
      <div className="exColx max-w-[1400px] mx-auto my-[2em]">
        
          <Swiper
                loop={true}
                modules={[Pagination, Navigation]}
                spaceBetween={20}
                // centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // navigation
                // pagination={{ clickable: true, }}
                // modules={[Autoplay, Pagination, Navigation]}
                className=""
                ref={exCollection}
        
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    // spaceBetween: 10,
                  },
                  375: {
                    slidesPerView: 2,
                    // spaceBetween: 10,
                  },
                  548: {
                    slidesPerView: 2,
                    // spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 3,
                    // spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    // spaceBetween: 10,
                  },
                  900: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  1280: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  1600: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  2100: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                }}
          >
          <div className="my-10 items-center justify-center">
            <div className=" grid-cols-1 space-x-7 md:grid-cols-2 lg:grid-cols-4">
              {exclusiveCollectionData?.map((item, index) => {
                const newImg = item.product_image ? item.product_image.split(",") : [];
                return (
                  <SwiperSlide key={index} >
                    <div className='' to="" key={item.id} onClick={() => setProductdataInLocalstorage(item.id)}>
                      <div className='img-wrapper relative rounded-lg'>
                        <img className='w-full bg-white' src={`${imgPath}/${newImg[0]}`} alt='ExclusiveCollections poster' />
                        <div className='absolute bottom-0 text-center w-full px-2 py-3'>
                          <p className='text-white text mb-5 w-full'>{(item?.product_name).substring(0, 35)}</p>
                          <button onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name, item.cat_id)} className='bg-red-600 text-white font-thin text-sm p-2 w-36 uppercase'>View Products</button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
              <div className="sliderNav-wrapper">
                  <svg onClick={() => exCollection.current.swiper.slidePrev()} className='slideNav-previous w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute right-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                  <svg onClick={() => exCollection.current.swiper.slideNext()} className='slideNav-next w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute left-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                  </svg>
              </div>
            </div>
          </div>
          {/* <div onClick={nextBtn} className='w-12 h-12 bg-red-200 absolute right-1 top-[50%] flex justify-center items-center rounded-full'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-10 cursor-pointer text-red-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </div>
          <div onClick={prevBtn} className='w-12 h-12 bg-red-200 absolute left-1 top-[50%] flex justify-center items-center rounded-full'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-10 cursor-pointer text-red-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </div> */}
          </Swiper>
        
      </div>
    </div>
  )
}

const ExclusiveCollections = memo(ExclusiveCollectionsMemorize);
export default ExclusiveCollections;
